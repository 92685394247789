import { Navbar } from "./Animation/Navbar";
import { LineDrawing } from "./Home/LineDrawing";
import { HomeComponent } from '../components/Home/Home';
import { Overlay } from './Animation/Overlay';
import FadeInAnimation from '../helpers/animation';
import { ContactComponent } from "./Contact/ContactBanner";
import { RightFrameComponent } from "./Details/RightFrame";

import { AdditionalComponent } from "./Details/AdditionalInfo";
export {
    Navbar,
    LineDrawing,
    HomeComponent,
    Overlay,
    FadeInAnimation,
    ContactComponent,
    RightFrameComponent,
    AdditionalComponent
}