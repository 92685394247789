import React from "react";
import { Navbar, LineDrawing } from '../exports';

export class HomeComponent extends React.Component {
  render() {
    return (
      <div className="home">
        <Navbar />
        <LineDrawing />
      </div>
    )
  }
}

