import React from "react";

const positions = [
    {
        "name": "Granges Americas",
        "from": "August 2022",
        "to": "Current",
        "title": "Software Engineer"
    },
    {
        "name": "NetApp INC",
        "from": "September 2022 ",
        "to": "April 2023",
        "title": "MTS-Software Engineer I"
    },
    {
        "name": "Inmar Intelligence",
        "from": "May 2021",
        "to": "September 2022",
        "title": "Software Engineer"
    },
    {
        "name": "University of South Dakota-Biomedical Engineering Deparment",
        "from": "January 2019",
        "to": "December 2021",
        "title": "Undergraduate Research Assistant"
    },
    {
        "name": "University of South Dakota",
        "from": "August 2017",
        "to": "December 2021",
        "title": "Undergraduate Student"
    },
]

export class RightFrameComponent extends React.Component {

    render() {
        const journeyDetails = []
        positions.forEach(position => {
            journeyDetails.push(
                <div className="card" key={position.name}>
                    <div className="info">
                        <div className="timeline-card">{position.name}</div>
                        <div className="timeline-description"><p>{position.from} - {position.to}</p></div>
                    </div>
                </div>
            )
        });
        return (
            <div className="right-frame" >
                <div className="timeline">
                    <div className="outer">{journeyDetails}
                    </div>
                </div>
            </div>
        )
    }
}
