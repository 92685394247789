import React from "react";
import ReactDOM from "react-dom";
import { HomeComponent, Overlay, ContactComponent, AdditionalComponent } from '../src/js/components/exports';
import '../src/css/main.css';
import '../src/css/project.css';
import '../src/css/info.css';
import '../src/css/timeline.css'
import '../src/css/responsive.css'
const animation = require('../src/js/helpers/animation');

ReactDOM.render(<HomeComponent />, document.getElementById('main-component'));

ReactDOM.render(
    <Overlay />, document.getElementById("overlay-component"));

ReactDOM.render(
    <AdditionalComponent />, document.getElementById("about"));

ReactDOM.render(
    <ContactComponent />, document.getElementById("contact")
)