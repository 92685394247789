import React from "react";
import { RightFrameComponent } from "../exports";

export class AdditionalComponent extends React.Component {
    render() {
        return (
            <div>
                <h2 className="aboutHeader">About</h2>
                <br />
                <div className="dividedInfo">Hello! My name is Sabi Kaflay, SDE based out of Franklin, TN.
I currently work as a Software Developer at Granges Americas. I graduated from the University of South Dakota with a BS Computer Science Degree. In terms of hobbies, I enjoy painting and being a part-time reader, where I'm trying to finish my 250<sup>th</sup> novel this year!
                </div>
                <div className="additionalDetails">
                    <div className="left-section ">
                        <h4 className="header  my-4">Skills</h4>
                        <div className="details-list">
                            <p>Python</p>
                            <div className="meter orange nostripes"> <span className="python"></span></div>
                            <p>JavaScript</p>
                            <div className="meter blue nostripes"> <span className="js"></span></div>
                            <p>C#</p>
                            <div className="meter green nostripes"> <span className="c"></span></div>
                            <p>TypeScript</p>
                            <div className="meter purple nostripes"> <span className="ts"></span></div>
                            <p>React/Angular</p>
                            <div className="meter yellow nostripes"> <span className="react"></span></div>
                            <p>Java</p>
                            <div className="meter coral nostripes"> <span className="java"></span></div>
                        </div>
                    </div>
                    <div className="right-section">
                        <h3 className="header my-4">Journey so Far</h3>
                        <RightFrameComponent />
                    </div>
                </div>
            </div>
        )
    }
}
