import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin, faGithub, faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faLinkedin, faFontAwesome, faGithub);

export function ContactComponent() {
  return (
    <div>
      <div className="left-frame">
        <div className="project-header-box">
          <div className="project-header">
            <div className="icons-center flex-display">
              <a href="mailto: contact@sabikaflay.com" aria-label="Email Me" className="contact-icon mx-10">
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
              </a>
              <a href="https://www.linkedin.com/in/sabikaflay/" className="contact-icon mx-2" target="_blank">
                <FontAwesomeIcon icon="fa-brands fa-linkedin" />
              </a>
              <a href="https://github.com/skaflay" className="contact-icon mx-10" target="_blank">
                <FontAwesomeIcon icon="fa-brands fa-github" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}