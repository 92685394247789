import ReactDOM from "react-dom";
import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";


import image from '../../../images/static.svg';

export class LineDrawing extends React.Component {
  render() {
    return (
      <div className="container banner">
        <div className="svg-image fadeIn banner-image">
          <img src={image} />
        </div>
        <div id="header-text" className="banner-text">
          <h1>Sabi Kaflay</h1>
        </div>
      </div>
    )
  }
}