import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

const FadeInAnimation = ({
  children,
  wrapperElement = "section",
  delay = 1.1,
  ...props
}) => {
  const distance = 200;
  const fadeDirection = { y: -distance };
  const Component = wrapperElement;
  let compRef = useRef(null);
  useEffect(() => {
    gsap.from(compRef.current, 1, {
      ...fadeDirection,
      opacity: 0,
      delay,
      onComplete: () => {
        document.querySelector('#about').style.display = "block";
        document.querySelector('#contact').style.display = "block"
        document.querySelector('#overlay').style.display = "none"
      }
    });
  }, [compRef, fadeDirection, delay]);
  return (
    <Component ref={compRef} {...props}>
      {children}
    </Component>
  );
};
export default FadeInAnimation;


