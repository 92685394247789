import React from "react";
import { FadeInAnimation } from "../exports";
const navLinks = [{
  "link": "#about",
  "title": "About"
},
{
  "link": "https://github.com/skaflay",
  "title": "Projects"
},
{
  "link": "https://www.linkedin.com/in/sabikaflay/",
  "title": "Contact"
}]
export function Navbar() {
  const navData = [];
  navLinks.forEach(e => {
    navData.push(
      <li className="nav-item" key={e.title}>
        <a className="nav-link" key={e.title} href={e.link}>{e.title}</a>
      </li>
    )
  });
  return (
    <nav id="mainNav">
      <FadeInAnimation wrapperElement="ul" >
        <ul className="nav-list">
          {navData}
        </ul>
      </FadeInAnimation>
    </nav>
  )
}